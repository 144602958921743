import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretRight } from "@fortawesome/free-solid-svg-icons"

import '../styles/footer.scss';
import { Link } from "gatsby"
import { faFacebookF, faLinkedin, faTwitter } from "@fortawesome/free-brands-svg-icons"
import Container from "./container"
import logo from "../images/logo.svg"

const FooterListColumn = ({ items = [], title }) => (
  <div className="my-6 lg:my-0">
    <h4 className="mb-4 lg:mb-12 font-niramit font-bold text-xl font-primary-magenta">{title}</h4>

    <ul>
      {items.map(item => (
        <li key={Math.random()} className="mb-4 text-alpha-purple font-niramit">
          {item.url ? <a href={item.url}>{item.label}</a> : <span>{item.label}</span>}
        </li>
      ))}
    </ul>
  </div>
);

const Footer = () => {

  return (
    <footer className="relative overflow-hidden bg-sweet-blue -mt-24 pt-48">
      <Container className="flex flex-col">
        <div className="flex justify-center md:justify-between flex-row flex-wrap xl:flex-no-wrap items-center text-center md:text-left md:items-start -mx-6">
          <div className="w-full xl:w-auto px-6 mb-12 xl:mb-0">
            <Link to="/" className='flex justify-center items-center text-white'>
              <img className="logo sm" src={logo} alt="Inizio" />
            </Link>


            <div className="flex items-center justify-center xl:justify-start -mx-3 mt-4 pt-4">
              <a aria-label="facebook" href="https://www.facebook.com/WeAreInizio" target="_blank" className="px-3 inline-block">
                <div className="social-icon">
                  <FontAwesomeIcon size="lg" icon={faFacebookF} />
                </div>
              </a>

              <a aria-label="twitter" href="https://twitter.com/WeAreInizio" target="_blank" className="px-3 inline-block">
                <div className="social-icon">
                  <FontAwesomeIcon size="lg" icon={faTwitter} />
                </div>
              </a>

              <a aria-label="linkdein" href="https://www.linkedin.com/company/inizio-io" target="_blank" className="px-3 inline-block">
                <div className="social-icon">
                  <FontAwesomeIcon size="lg" icon={faLinkedin} />
                </div>
              </a>
            </div>
          </div>

          <div className=" px-6 w-full md:w-1/2 lg:w-1/4 xl:w-auto">
            <FooterListColumn
              title="Information"
              items={[
                {
                  label: 'San Jose, CA'
                },
                {
                  url: 'mailto:contact@inizio.io',
                  label: 'contact@inizio.io'
                },
              ]}
            />

            
          </div>

          <div className="px-6  w-full md:w-1/2 lg:w-1/4 xl:w-3/12">
            <FooterListColumn
              title="Stay in Loop"
              items={[
                {
                  label: 'Subscribe to receive Inizio newsletter!'
                },
              ]}
            />

            <Link className="btn btn-primary btn-sm mt-4" to="/subscribe">SUBSCRIBE</Link>
          </div>

          <div className="px-6 w-full md:w-1/2 lg:w-1/4 xl:w-auto">
            <FooterListColumn
              title="About Company"
              items={[
                {
                  url: '/blog',
                  label: 'Blog'
                },
                {
                  url: 'mailto:contact@inizio.io',
                  label: 'Careers'
                },
              ]}
            />
          </div>
        </div>

        <div className="text-center font-niramit text-lg text-alpha-purple mt-24 mb-48">
          Inizio, Inc © {new Date().getFullYear()} All rights reserved.
        </div>
      </Container>

      <div className="hidden lg:block footer-blue-shape" />

      <div className="hidden lg:block footer-bg-shape right-bottom" />
      <div className="hidden lg:block footer-bg-shape right-top" />

      <div className="hidden lg:block footer-bg-shape center-bottom" />

      <div className="hidden lg:block footer-bg-shape left-top" />
      <div className="hidden lg:block footer-bg-shape left-center" />
      <div className="hidden lg:block footer-bg-shape left-bottom" />
    </footer>
  )
}

export default Footer;
