import React from "react"
import PropTypes from "prop-types"
import "../styles/common.scss";
import "../styles/overrides.scss";
import "../styles/tailwind.css";

import Footer from "./footer"
import Header from "./Header"
import { HEADER_THEME, HEADER_TYPE } from "../consts"

const Layout = ({ children, isExternal, className, withoutFooter = false }) => {
  return (
    <div className={className}>

      <Header isExternal={isExternal} />

      <main className="overflow-hidden">{children}</main>

      {!withoutFooter && (
        <Footer />
      )}

      {/*<ScrollTopButton />*/}
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  headerType: PropTypes.shape(),
  className: PropTypes.string,
}

Layout.defaultProps = {
  headerType: {
    type: HEADER_TYPE.LANDING,
    theme: HEADER_THEME.DEFAULT,
    className: ''
  }
}

export default Layout
