import { Link } from "gatsby"
import { map, throttle } from 'lodash';
import PropTypes from "prop-types"
import React, { useRef, Fragment, useEffect, useState, useContext } from "react"

import logo from '../../images/logo.svg';
import './styles.scss';
import Hamburger from "./Hamburger"
import { HEADER_NAVIGATION, HEADER_THEME, HEADER_TYPE } from "../../consts"
import HeaderContext from "../../context/Header"
import ZohoContext from "../../context/ZohoProvider"

// isExternal - any page that isn't Index
const Header = ({ isExternal }) => {
  const ScrollENUM = {
    UP: 0,
    DOWN: 1
  };

  const [scrollDirection, onScrollChange] = useState(ScrollENUM.UP);
  const navbarRef = useRef(null);
  const delta = 120;
  let lastScrollPosition = 0;

  const onScroll = (e) => {
    const element = navbarRef.current;

    const scrollTop = window.pageYOffset;

    if (Math.abs(lastScrollPosition - scrollTop) <= delta)
      return;

    if (!element) return;

    if (scrollTop > lastScrollPosition && scrollTop > element.clientHeight) {
      onScrollChange(ScrollENUM.DOWN);
    } else {
      if ((scrollTop + window.innerHeight) < document.body.clientHeight) {
        onScrollChange(ScrollENUM.UP);
      }
    }

    lastScrollPosition = scrollTop;
  }

  useEffect(() => {
    window.addEventListener('scroll', throttle(onScroll, 500));

    return () => {
      window.removeEventListener('scroll', throttle(onScroll, 500));
    }
  }, []);

  const renderNavItem = (item, onMenuStateChange) => (
    <li className="nav-item" key={item.LABEL}>
      {/[#]/g.test(item.URL) && !isExternal ? (
        <a onClick={() => onMenuStateChange(false)} className="nav-link" href={item.URL}>
          {item.LABEL}
          <div className="wave" />
        </a>
      ) : (
        <Link className="nav-link" state={{ anchor: item.URL }} to={isExternal ? '/' : item.URL}>
          {item.LABEL}
          <div className="wave" />
        </Link>
      )}
    </li>
  )

  return (
    <header ref={navbarRef} className={`navbar landing ${scrollDirection === 1 ? 'navbar-scrolled' : ''}`}>
      <div className="flex items-center justify-between flex-wrap px-6 w-full md:w-5/6 md:mx-auto navbar-default relative pt-4 pb-4  ">
        <div className="mr-20">
          <Link to="/" className='lg:flex items-center justify-center logo text-white'>
            <img className="logo" src={logo} alt="Inizio" />
          </Link>
        </div>

        <HeaderContext.Consumer>
          {({ isMenuOpen, onMenuStateChange }) => (
            <Fragment>
              <Hamburger
                isOpen={isMenuOpen}
                onMenuStateChange={onMenuStateChange} />

              <nav className={`w-8/12 items-center navbar-collapse ${isMenuOpen ? 'open' : ''}`}>
                <div className="nav-container-outer w-full">
                  <div className="nav-container-inner">
                    <ul className="nav-container flex-col flex justify-between lg:flex-row ">

                      {map(HEADER_NAVIGATION, item => renderNavItem(item, onMenuStateChange))}
                      <li>
                        <a href="#contact"  className="cursor-pointer btn btn-primary mt-4 lg:mt-0">Contact Us</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </nav>
            </Fragment>
          )}
        </HeaderContext.Consumer>
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
  type: PropTypes.shape(),
}

Header.defaultProps = {
  siteTitle: ``,
  type: {
    type: HEADER_TYPE.LANDING,
    theme: HEADER_THEME.DEFAULT
  }
}

export default Header
