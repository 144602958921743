import { omit } from 'lodash';

export const HEADER_THEME = {
  DEFAULT: 'default',
  INVERSE: 'inverse',
}

export const HEADER_TYPE = {
  LANDING: 'landing',
  BLOG: 'blog'
}

export const NAVIGATION = {
  WHAT_WE_DO: {
    LABEL: 'what we do',
    URL: '#what-we-do'
  },
  WHY_US: {
    LABEL: 'why us',
    URL: '#why-us'
  },
  OUR_WORK: {
    LABEL: 'our work',
    URL: '#our-work'
  },
  TESTIMONIALS: {
    LABEL: 'testimonials',
    URL: '#testimonials'
  },
  TEAM: {
    LABEL: 'Team',
    URL: '#team'
  },
  CONTACT: {
    LABEL: 'Contact',
    URL: '#contact'
  },
  BLOG: {
    LABEL : 'Blog',
    URL: '/blog'
  }
}

export const HEADER_NAVIGATION = {
  ...omit(NAVIGATION, ['CONTACT', 'TEAM'])
}
