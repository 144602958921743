import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import './styles.scss';

const Hamburger = ({ isOpen, onMenuStateChange }) => (
  <div className="navbar-toggler-container">
    <button
      name="menu"
      type="button"
      onClick={() => onMenuStateChange(!isOpen)}
      className={`navbar-toggler lg:hidden focus:outline-none ${isOpen ? 'open' : ''}`}>
        <span />
        <span />
        <span />
        <span />
        <span />
        <span />
        menu
    </button>
  </div>
)

Hamburger.propTypes = {
  onMenuStateChange: PropTypes.func,
}

Hamburger.defaultProps = {
  onMenuStateChange: () => null
}

export default Hamburger;
